import React, { useContext } from 'react';
import { BasicData } from '@App';
import { Trans } from '@lingui/macro';
import { scrollTo } from '@utils/Utils';

import './style.scss';

const NavBarMenu = ({ setDropdownOpen }) => {
    const { theme } = useContext(BasicData);

    const toggleDropdown = () => {
        setDropdownOpen(prev => !prev);
    }

    const renderTripSummaryOption = (theme) => {
        if(theme?.template_settings?.ItineraryBlocks) 
            return (
                <li><h6 onClick={() => scrollTo('trip-summary-overview')}><Trans>Trip Summary</Trans></h6></li>
            );

        if(theme?.template_settings?.ItineraryBlocks?.length > 0 && 
            theme.template_settings.ItineraryBlocks.findIndex(x => x.Type === "TripSummary") > -1)
            return (
                <li><h6 onClick={() => scrollTo('trip-summary-overview')}><Trans>Trip Summary</Trans></h6></li>
            );

        return;
    }

    const renderItinerarySummaryOption = (theme) => {
        if(theme?.template_settings?.ItineraryBlocks) 
            return (
                <li onClick={toggleDropdown} id="btn-toggle-dropdown">
                    <h6 style={{ pointerEvents: 'none' }}><Trans>Itinerary</Trans></h6>
                    <i style={{ pointerEvents: 'none' }} className='icon icon-chevron-down'></i>
                </li>
            );

        if(theme?.template_settings?.ItineraryBlocks?.length > 0 && 
            theme.template_settings.ItineraryBlocks.findIndex(x => x.Type === "DayByDaySummary") > -1)
            return (
                <li onClick={toggleDropdown} id="btn-toggle-dropdown">
                    <h6 style={{ pointerEvents: 'none' }}><Trans>Itinerary</Trans></h6>
                    <i style={{ pointerEvents: 'none' }} className='icon icon-chevron-down'></i>
                </li>
            );

        return;
    }

    return (
        <ul className={`nav-bar-menu`}>
            <li><h6 onClick={() => scrollTo('home')}><Trans>Home</Trans></h6></li>
            {renderTripSummaryOption(theme)}
            {renderItinerarySummaryOption(theme)}
        </ul>
    )
}

export default React.memo(NavBarMenu);