import React, { lazy, Suspense, useContext } from "react";

import { BasicData } from "@App";

import "./style.scss";

// Components
import SpinnerComponent from "@components/spinner";
import CoverPage from "@components/cover-page";
import NavBarComponent from "@components/nav-bar";
import BodyContainer from "@containers/body";
import Contact from "@components/contact";
import BackToTop from "@components/back-to-top";

// Icons

// Lazy loaded Components
const EnquiryForm = lazy(() => import("@components/enquiry-form"));
const IntroductionComponent = lazy(() => import("@components/trip-summary/introduction"));
const ScheduledDepraturesComponent = lazy(() => import("@components/trip-summary/scheduled-departures"));
const FastFactsComponent = lazy(() => import("@components/trip-summary/fast-facts"));
const OverviewComponent = lazy(() => import("@components/trip-summary/overview"));
const TransportComponent = lazy(() => import("@components/trip-summary/transport"));
const InteractiveMapWrap = lazy(() => import("@components/interactive-map-wrapper"));
const PricingComponent = lazy(() => import("@components/trip-summary/pricing"));
const DocumentsComponent = lazy(() => import("@components/trip-summary/documents"));
const TermsAndConditionsComponent = lazy(() => import("@components/terms-and-conditions"));
const TravelGuidanceComponent = lazy(() => import("@components/travel-guidance"));
const FooterComponent = lazy(() => import("@components/footer"));

const LayoutComponent = ({ template }) => {
  const basicData = useContext(BasicData);
  const { documents, summary, price, price_includes, price_excludes, scheduled_departures } = useContext(BasicData);
  const showPricing = price || price_includes || price_excludes;

  const getItineraryBlockComponent = (block, i) => {
    switch (block.Type) {
      case "Introduction":
        if (summary)
          return (
            <Suspense fallback={<SpinnerComponent />}>
              <IntroductionComponent />
            </Suspense>
          );
        else return;
      case "ScheduledDepartures":
        if (scheduled_departures)
          return (
            <Suspense fallback={<SpinnerComponent />}>
              <ScheduledDepraturesComponent />
            </Suspense>
          );
        else return;
      case "FastFacts":
        return (
          <Suspense fallback={<SpinnerComponent />}>
            <FastFactsComponent />
          </Suspense>
        );
      case "TripSummary":
        return (
          <div id="trip-summary-overview">
            <Suspense fallback={<SpinnerComponent />}>
              <OverviewComponent />
            </Suspense>
          </div>
        );
      case "InteractiveMap":
        if (basicData)
          return (
            <Suspense fallback={<SpinnerComponent />}>
              <InteractiveMapWrap basicData={basicData} />
            </Suspense>
          );
        else return;
      case "TransportSummary":
        return (
          <Suspense fallback={<SpinnerComponent />}>
            <TransportComponent />
          </Suspense>
        );
      case "Pricing":
        if (showPricing)
          return (
            <Suspense fallback={<SpinnerComponent />}>
              <PricingComponent />
            </Suspense>
          );
        else return;
      case "Documents":
        if (documents)
          return (
            <Suspense fallback={<SpinnerComponent />}>
              <DocumentsComponent />
            </Suspense>
          );
        else return;
      case "DayByDaySummary":
        return <BodyContainer />;
      case "Enquiry":
        return (
          <Suspense fallback={<SpinnerComponent />}>
            <EnquiryForm />
          </Suspense>
        );
      case "TravelGuidance":
        if (basicData?.travel_guidance)
          return (
            <Suspense fallback={<SpinnerComponent />}>
              <TravelGuidanceComponent />
            </Suspense>
          );
        else return;
      case "TermsAndConditions":
        if (basicData?.terms_and_conditions || basicData?.branding?.terms_and_conditions)
          return (
            <Suspense fallback={<SpinnerComponent />}>
              <TermsAndConditionsComponent />
            </Suspense>
          );
        else return;
      default:
        return;
    }
  };

  const itineraryBlockRender = (block, i) => {
    return (
      <div key={i}>
        {getItineraryBlockComponent(block, i)}
      </div>
    );
  };

  return (
    <div className="body">
      <NavBarComponent />
      <CoverPage itineraryDisabled={false} />

      {/* Blocks */}
      {template?.ItineraryBlocks.map(itineraryBlockRender)}
      {/* Blocks */}

      <Suspense fallback={<SpinnerComponent />}>
        <FooterComponent />
      </Suspense>

      {/* Settings */}
      <div className="floating-elements">
        {template?.IsContactFABVisible && basicData?.branding && <Contact />}
        <BackToTop />
      </div>
      {/* Settings */}
    </div>
  );
};

export default LayoutComponent;
